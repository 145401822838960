import { TestStatusEnum } from '@doltech/utils/lib/constants';
import * as React from 'react';
import { ButtonDictation } from '../../landing/ui/ButtonDictation/ButtonDictation';
import { IconButtonsPlayPauseO } from '@doltech/icons/IconButtonsPlayPauseO';
import { IconMultimediaPlayButtonO } from '@doltech/icons/IconMultimediaPlayButtonO';
import { IconArrowsRefresh } from '@doltech/icons/IconArrowsRefresh';

export const DictationActionLoader = (props) => {
  const { status, url } = props;
  switch (status) {
    case TestStatusEnum.IN_PROGRESS:
      return (
        <ButtonDictation
          type="continue"
          url={url}
          text="Chép tiếp"
          icon={<IconButtonsPlayPauseO size={20} color="blue100" />}
        />
      );
    case TestStatusEnum.COMPLETED:
      return (
        <ButtonDictation
          type="redo"
          url={url}
          text="Chép lại"
          icon={<IconArrowsRefresh size={20} color="black60" />}
        />
      );
    default:
      return (
        <ButtonDictation
          type="start"
          url={url}
          text="Chép bài"
          icon={<IconMultimediaPlayButtonO size={20} color="primary100" />}
        />
      );
  }
};
