import { apis } from '@doltech/core/lib/api/api.config';
import { cacheWrap } from '@doltech/core/lib/cache/cache-manager';
import { components as pm } from '@doltech/pageManagement/dto';
import { ElasticRequestBody, EslasticResponse, TransformedElasticPage } from '@doltech/types';
import { MyTestFilterStatusEnum } from '@doltech/utils/lib/constants';
import { transformElasticResponse } from '@doltech/utils/lib/elastic';
import { genEmptyPagable } from '@doltech/utils/lib/object';
import { cloneDeep } from 'lodash';

import { getMyDictationIds } from '../../home-page/api/dol-v1_5.api';
import { apiPublicUrls } from '@doltech/core/lib/api/api.public-urls';

export const fetchPublicDictationOverview = async (params = {}) => {
  const { data } = await apis.publicPageManagement.get<
    pm['schemas']['PageDictationWithOverviewDTO']
  >(apiPublicUrls.pageManagement.GET_DICTATIONS_OVERVIEW, params);

  return data;
};

/**
 * Add `elastic` field
 */

export type FetchPublicDictationTestElasticResult = {
  data: pm['schemas']['PageDictationWithOverviewDTO'];
  page: TransformedElasticPage;
};

export const fetchPublicDictationOverviewElastic = async (
  payload: ElasticRequestBody
): Promise<FetchPublicDictationTestElasticResult> => {
  return cacheWrap(`fetchPublicPracticeTestElastic_${JSON.stringify(payload)}`, async () => {
    const filterPayload = cloneDeep(payload);

    const statusFilterIndex = payload.filters.all.findIndex((o) => o?.status);
    const haveStatusFilter = statusFilterIndex > -1;

    let myDictationIds = [];
    if (haveStatusFilter) {
      const filterStatusValue = filterPayload.filters.all[statusFilterIndex].status;
      switch (filterStatusValue) {
        case MyTestFilterStatusEnum.NEW: {
          const res: any = await getMyDictationIds({});
          myDictationIds = res?.content || [];
          if (!filterPayload.filters.none) {
            filterPayload.filters.none = [];
          }
          filterPayload.filters.none = filterPayload.filters.none.filter((obj) => !obj?.id);
          filterPayload.filters.none = [
            ...filterPayload.filters.none,
            {
              id: myDictationIds as any,
            },
          ];
          break;
        }
        case MyTestFilterStatusEnum.IN_PROGRESS:
        case MyTestFilterStatusEnum.COMPLETED: {
          const res: any = await getMyDictationIds({
            statuses: [filterStatusValue],
          });
          myDictationIds = res?.content || [];
          filterPayload.filters.all = filterPayload.filters.all.filter((obj) => !obj?.id);
          filterPayload.filters.all = [
            ...filterPayload.filters.all,
            {
              id: myDictationIds as any,
            },
          ];
          break;
        }
        default:
      }
    }
    filterPayload.filters.all = filterPayload.filters.all.filter((o) => !o?.status && !o.sort);
    const { facets, ...rest } = filterPayload;
    const { data: response } = await apis.publicSearchTransform.post<EslasticResponse>(
      apiPublicUrls.searchTransform.GET_LANDING_FILTER_ELASTIC,
      rest,
      {
        params: {
          contentGroup: 'DICTATION',
        },
      }
    );

    const { results, page } = transformElasticResponse(response);

    const ids = results.map((r) => r.id);
    let data;

    if (ids.length > 0) {
      data = results;
    } else {
      data = genEmptyPagable({ size: payload.page.size });
    }

    return {
      data,
      page,
    };
  });
};
