import {
  StructuralCard,
  StructuralItemLayout,
} from '@doltech/ui/lib/figma/Card/DolCard/TextOnlyCard/StructuralCard';
import { Link } from '@doltech/ui/lib/figma/Link';
import no_image from '@doltech/ui/lib/images/No_image_available.png';
import { imgProxyLoader } from '@doltech/utils/lib/image-loader';
import { getFullUrl, getLandingUrlFromPath } from '@doltech/utils/lib/url';

import { Box } from '@doltech/ui/lib/figma/Common/BoxV2';
import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import styled from 'styled-components';

import numeral from 'numeral';
import * as React from 'react';
import { useQuery } from 'react-query';

import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';
import { fetchPublicDictationOverview } from '../api/dictation';
import { DictationActionLoader } from './DictationActionLoader';
import { DictationTestStatusLoader } from './DictationTestStatusLoader';
import { DictationType } from '@doltech/domains/dictation/shared/constant';

interface PracticeTestCardV2Props {
  data: any;
}
const TestStatusWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
`;

export const DictationCard = observer((props: PracticeTestCardV2Props) => {
  const { data } = props;
  const { id, takes, snippet, name } = data;
  const { isLoggedIn } = useGetAuthInfo();

  const dictationOverviewQuery = useQuery(
    [id, isLoggedIn],
    async () => {
      const res = await fetchPublicDictationOverview({ ids: [id], size: 1 });
      return res?.content?.[0];
    },
    {
      retry: 1,
      retryDelay: 500,
      refetchOnMount: true,
    }
  );

  const pageUrl = React.useMemo(() => {
    const pages = dictationOverviewQuery?.data?.pages || [];
    return getLandingUrlFromPath(pages?.[0]?.url);
  }, [dictationOverviewQuery?.data?.pages]);

  const imageProps = React.useMemo(() => {
    const result: any = {
      height: 'auto',
      width: '100%',
      ratio: 3 / 2,
      isLoading: dictationOverviewQuery.isLoading,
      borderRadius: '8px',

      url: pageUrl,
      imageUrl: dictationOverviewQuery.data?.cover?.url,
      alt: dictationOverviewQuery.data?.cover?.alt || name,
    };
    return result;
  }, [
    dictationOverviewQuery.data?.cover?.alt,
    dictationOverviewQuery.data?.cover?.url,
    dictationOverviewQuery.isLoading,
    pageUrl,
    name,
  ]);

  const metaOrDescription = React.useMemo(() => {
    const pages = dictationOverviewQuery?.data?.pages || [];

    const doDictation = pages[0];
    const metaTakes = {
      id: 'NO_OF_TAKEN',
      text: `${numeral(takes || doDictation?.takes || 0).format('0, 0a')} lượt chép`,
    };

    const metaType = {
      id: 'TYPE',
      text: DictationType[dictationOverviewQuery?.data?.type],
    };

    return {
      type: 'META',
      defaultProps: {
        metadata: [metaType, metaTakes].filter(Boolean),
      },
    };
  }, [dictationOverviewQuery?.data?.pages, dictationOverviewQuery?.data?.type, takes]);

  const testAction = React.useMemo(() => {
    const doDictationStatus = dictationOverviewQuery?.data?.doDictationStatus;
    return {
      type: 'CUSTOM',
      isLoading: dictationOverviewQuery.isLoading,
      render: () => {
        return <DictationActionLoader status={doDictationStatus} url={pageUrl} />;
      },
    };
  }, [dictationOverviewQuery?.data?.doDictationStatus, dictationOverviewQuery.isLoading, pageUrl]);

  const structuralCardItems: StructuralItemLayout[] = React.useMemo(() => {
    const cardLayout: any = {
      direction: 'vertical',
      gridTemplate: {
        areas: `
        "image"
        "card-content"`,
        rows: 'max-content 1fr',
        gap: [12, 12],
      },
    };
    return [
      {
        dataIndex: 'card-main',
        ...cardLayout,
        items: [
          {
            dataIndex: 'image',
            type: 'IMAGE',
            defaultProps: imageProps,
            render: () => {
              const { url, alt, imageUrl, ratio, ...rest } = imageProps;
              return (
                <Link prefetch={false} href={getFullUrl(imageProps?.url)} className="image-link">
                  <Box className="image-wrapper" {...rest}>
                    <Image
                      width={1}
                      height={1 / imageProps?.ratio}
                      alt={imageProps?.alt}
                      src={imageProps?.imageUrl || no_image}
                      quality={30}
                      objectFit="cover"
                      layout="responsive"
                      loading="lazy"
                      loader={imgProxyLoader}
                    />
                    <TestStatusWrapper>
                      <DictationTestStatusLoader data={dictationOverviewQuery?.data as any} />
                    </TestStatusWrapper>
                  </Box>
                </Link>
              );
            },
          },
          {
            dataIndex: 'card-content',
            direction: 'vertical',
            gridTemplate: {
              gap: [12, 12],
              rows: '1fr max-content',
              areas: `
              "card-info"
              "card-action"
              `,
            },
            items: [
              {
                dataIndex: 'card-info',
                direction: 'vertical',
                gridTemplate: {
                  gap: [4, 4],
                  rows: 'max-content max-content',
                  areas: `
                 "title"
                 "meta-or-description"
                 `,
                },
                items: [
                  {
                    dataIndex: 'title',
                    type: 'TITLE',
                    defaultProps: {
                      children: name,
                      url: pageUrl,
                      snippet: snippet?.name,
                    },
                  },
                  {
                    dataIndex: 'meta-or-description',
                    ...metaOrDescription,
                  },
                ],
              },
              {
                dataIndex: 'card-action',
                ...testAction,
              },
            ],
          },
        ],
      },
    ];
  }, [
    imageProps,
    metaOrDescription,
    name,
    pageUrl,
    snippet?.name,
    testAction,
    dictationOverviewQuery?.data,
  ]);

  return <StructuralCard className="dictation-card" items={structuralCardItems} />;
});
