/* eslint-disable no-console */
import fs from 'fs';
import path from 'path';

import { environment } from '../environment/environment';
import md5 from 'md5';

const CACHE_DIR = path.resolve(process.cwd(), '.fetch-cache');
const ttl = process.env.CI ? 60 * 60 : environment.LANDING_GLOBAL_CACHE_TTL || 30; // in seconds

interface CacheDataFormat {
  ttl: number;
  saveTime: number;
  data: any;
}

export const cacheWrap = async (key, asyncFn, overideTTL = ttl) => {
  let cachedData;
  const normalizeKey = md5(key.replace(/[^0-9a-zA-Z_]+/g, ''));

  if (typeof window !== 'undefined') {
    return asyncFn();
  }

  try {
    const diskCachedData: CacheDataFormat = JSON.parse(
      fs.readFileSync(path.join(CACHE_DIR, normalizeKey), 'utf8')
    );

    const currentTime = new Date().getTime();
    const isExpired = (currentTime - diskCachedData.saveTime) / 1000 > (overideTTL || ttl);
    if (!isExpired) {
      cachedData = diskCachedData.data;
      console.info(`${normalizeKey} cache hit`);
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}

  if (!cachedData) {
    console.info(`${normalizeKey} cache missed`);
    const data = await asyncFn();
    const pathToSave = path.join(CACHE_DIR, normalizeKey);

    const dataToSave = {
      ttl,
      saveTime: new Date().getTime(),
      data,
    };

    if (!fs.existsSync(CACHE_DIR)) {
      fs.mkdirSync(CACHE_DIR);
    }
    try {
      fs.writeFileSync(pathToSave, JSON.stringify(dataToSave), 'utf8');
    } catch (error) {
      console.log(`Error when cache ${normalizeKey}`);
      console.error(error);
    }

    cachedData = data;
  }

  return cachedData;
};
