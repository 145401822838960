export const CourseDictationStatusEnum = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
} as const;

export type CourseDictationStatusEnum =
  (typeof CourseDictationStatusEnum)[keyof typeof CourseDictationStatusEnum];

export const DictationType = {
  VIDEO: 'Video',
  AUDIO: 'Audio',
} as const;

export type DictationType = (typeof DictationType)[keyof typeof DictationType];

export const DICTATION_LIST_TOGGLE_ID = 'dictation-list-toggle';

export const wordDictationFont = {
  weight: '400',
  size: '20px',
  family: 'Inter',
};

export const DictationLevel = {
  SECTION_1: 'Section 1',
  SECTION_2: 'Section 2',
  SECTION_3: 'Section 3',
  SECTION_4: 'Section 4',
} as const;

export type DictationLevel = (typeof DictationLevel)[keyof typeof DictationLevel];

export const DictationViewModeEnum = {
  DO_DICTATION: 'DO_DICTATION',
  SHADOWING: 'SHADOWING',
  VOCAB: 'VOCAB',
  PREVIEW: 'PREVIEW',
} as const;

export type DictationViewModeEnum = (typeof DictationViewModeEnum)[keyof typeof DictationViewModeEnum];

export const DUAL_TOGGLE = {
  SUGGEST_TEXT: 'SUGGEST_TEXT',
  FREE_TEXT: 'FREE_TEXT'
}