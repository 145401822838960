import { TestStatusEnum } from '@doltech/utils/lib/constants';
import * as React from 'react';
import { ResponsiveTagStatus } from '../../landing/ui/ResponsiveTagStatus/ResponsiveTagStatus';

interface DictationTestStatusLoaderProps {
  data: {
    doDictationStatus: string;
    noOfCompletedSentences: number;
    noOfSentences: number;
  };
}

export const DictationTestStatusLoader = (props: DictationTestStatusLoaderProps) => {
  const { data } = props;
  const { doDictationStatus, noOfCompletedSentences = 0, noOfSentences } = data || {};
  switch (doDictationStatus) {
    case TestStatusEnum.IN_PROGRESS:
      return (
        <ResponsiveTagStatus
          status="in-progress"
          text={`Đang chép ${noOfCompletedSentences}/${noOfSentences}`}
        />
      );
    case TestStatusEnum.COMPLETED:
      return (
        <ResponsiveTagStatus
          status="done"
          text={`Đúng ${noOfCompletedSentences}/${noOfSentences}`}
        />
      );
    default:
      return <ResponsiveTagStatus status="not-started" text={`${noOfSentences} câu`} />;
  }
};
