import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { Link } from '@doltech/ui/lib/figma/Link';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { innerBorder } from '@doltech/utils/lib/css-style';
import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

interface ButtonDictationProps {
  type: 'start' | 'redo' | 'continue';
  url: string;
  text: string;
  icon: React.ReactNode;
}

const Main = styled.button`
  background-color: ${colorsV2.white100};
  padding: 8px 12px;
  border-radius: 8px;
  border: none;
  cursor: pointer;

  ${innerBorder(`1px solid ${colorsV2.gray20}`)}

  &.start {
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        ${innerBorder(`1px solid ${colorsV2.primary100}`)}
      }
    }

    &:active {
      ${innerBorder(`1px solid ${colorsV2.primary100}`)}
    }
  }

  &.continue {
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        ${innerBorder(`1px solid ${colorsV2.blue100}`)}
      }
    }

    &:active {
      ${innerBorder(`1px solid ${colorsV2.blue100}`)}
    }
  }

  &.redo {
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        ${innerBorder(`1px solid ${colorsV2.gray60}`)}
      }
    }

    &:active {
      ${innerBorder(`1px solid ${colorsV2.gray60}`)}
    }
  }
`;

const ContentLayout = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonDictation = (props: ButtonDictationProps) => {
  const { type, url, icon, text } = props;
  return (
    <Link href={url} target="_blank">
      <Main className={cl('button-dictation', type)}>
        <ContentLayout>
          {icon}
          <ResponsiveTypography.Paragraph variant="semi-bold/14-24" ml="8px" color="black100">
            {text}
          </ResponsiveTypography.Paragraph>
        </ContentLayout>
      </Main>
    </Link>
  );
};
